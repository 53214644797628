import React, { useState } from 'react';
import DatePicker from '../components/DateInput/DatePicker';
import isEqual from 'date-fns/is_equal';

const Date = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const _handleOnDateSelected = ({ selected, selectable, date }) => {
    console.log(date, selectedDate);
    console.log(date === selectedDate);

    const datesAreEqual = isEqual(date, selectedDate);

    if (datesAreEqual) {
      setSelectedDate(null);
    } else {
      setSelectedDate(date);
    }
  };

  return (
    <div>
      <DatePicker
        selected={selectedDate}
        onDateSelected={_handleOnDateSelected}
      />

      {selectedDate && (
        <div>
          <p>Selected:</p>
          <p>{`${selectedDate.toLocaleDateString()}`}</p>
        </div>
      )}
    </div>
  );
};

export default Date;
